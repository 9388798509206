import { useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

import UserProfileScreenExtended from "./UserProfileScreenExtended";
import ActionButtons from "./ActionButtons";
import { ProfileHeader } from "./ProfileHeader";

export interface SplitNameProps {
  firstName: string;
  lastName: string;
  shorterLastName: string;
}

function splitName(fullName: string) {
  const parts = fullName.trim().split(" ");
  const firstName = parts[0];
  const lastName = parts.slice(1).join(" ");
  const shorterLastName = parts[1]?.split("-")[0] || "";

  return {
    firstName,
    lastName,
    shorterLastName,
  };
}

const userName = splitName("Oscar Walde-Jacobsen");
const content =
  "I'm RSE Enterprise Fellow in the Biochemistry Department in Cambridge and a GFC Fellow in Innovation & Group leader,Faculty of Biochemistry and Exclusive sharp flat white Nordic soft power extraordinary, lovely smart perfect. Emerging Shinkansen smart hub flat white Nordic soft power extraordinary, lovely smart perfect. Emerging. flat white Nordic soft power extraordinary, lovely smart perfect. Emerging Shinkansen smart hub ....";

export default function UserProfileScreen() {
  const targetRef = useRef<HTMLDivElement>(null);
  const [isFavorited, setIsFavorited] = useState(false);

  const scrollToSection = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFavoriteChange = (newState: boolean) => {
    setIsFavorited(newState);
  };

  return (
    <div className="h-screen w-full">
      <div className="flex">
        <div className="h-screen w-full bg-user-purple text-white md:w-[460px]">
          <div className="relative h-full">
            <ProfileHeader
              onBackClick={() => window.history.back()}
              isFavorited={isFavorited}
              onFavoriteChange={handleFavoriteChange}
              onMenuOpen={() => {
                console.log("Menu opened"); //Placeholder for when we implement the menu
              }}
            />
            <div className="absolute inset-0 h-full w-full min-w-[360px]">
              <img
                src={"/user-profile-picture.png"}
                alt="Profile Background"
                className="min-h-[360px] w-full object-cover"
              />
              <div className="relative z-10 -mt-7 flex flex-col gap-4">
                <ActionButtons />
                <div className="flex flex-col gap-[14px] px-3">
                  <h1 className="text-3xl font-bold">
                    {userName.firstName + " " + userName.lastName}
                  </h1>
                  <p className="line-clamp-[8] overflow-hidden text-ellipsis whitespace-normal break-words text-lg leading-snug">
                    {content}
                  </p>
                </div>
              </div>
              <button
                onClick={scrollToSection}
                className="p absolute bottom-4 left-[50%] w-fit max-w-[460px] -translate-x-1/2 transition-transform hover:scale-110 sm:hidden"
                aria-label="Scroll to details"
              >
                <FaChevronDown size={24} />
              </button>
            </div>
          </div>
        </div>

        <div className="hidden w-full flex-1 bg-white p-6 md:block">
          {/* <ContentArea /> */} Content area
        </div>
      </div>
      <div className="w-full snap-start sm:hidden">
        <div ref={targetRef} className="relative h-screen pb-16 text-white">
          <UserProfileScreenExtended
            userName={userName}
            isFavorited={isFavorited}
            onFavoriteChange={handleFavoriteChange}
          />
        </div>
      </div>
    </div>
  );
}
