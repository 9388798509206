/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagDto
 */
export interface CreateTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagDto
     */
    keywords: string;
}

/**
 * Check if a given object implements the CreateTagDto interface.
 */
export function instanceOfCreateTagDto(value: object): value is CreateTagDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('keywords' in value) || value['keywords'] === undefined) return false;
    return true;
}

export function CreateTagDtoFromJSON(json: any): CreateTagDto {
    return CreateTagDtoFromJSONTyped(json, false);
}

export function CreateTagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'keywords': json['keywords'],
    };
}

  export function CreateTagDtoToJSON(json: any): CreateTagDto {
      return CreateTagDtoToJSONTyped(json, false);
  }

  export function CreateTagDtoToJSONTyped(value?: CreateTagDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'keywords': value['keywords'],
    };
}

