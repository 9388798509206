import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Page, PagesApi, User, UserResponse, UsersApi } from "../../apiClient/generated";
import { getApiConfig } from "../../apiClient/config";
import { isAuthenticated } from "../../utils/auth";

const BASE_URL = "https://devapi.mesensei.com";

export interface TempApp {
  identifier: string;
  id: string;
  name: string;
}

const TestContainer: React.FC = () => {
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);
  const [user] = useState<User | undefined>(undefined);
  const [app, setApp] = useState<TempApp | undefined>(undefined);
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [user2, setUser2] = useState<UserResponse | undefined>(undefined);
  const [isAuthToken, setIsAuthToken] = useState<boolean>(false);

  const [pingEvents, setPingEvents] = useState<string[]>([]);
  const [messageEvents, setMessageEvents] = useState<string[]>([]);

  const pagesApi = new PagesApi(getApiConfig());
  const usersApi = new UsersApi(getApiConfig());

  useEffect(() => {
    pagesApi.getPage({ id: "48b2233c-2409-47b6-942d-e8225660ecea" }).then((page) => {
      setPage(page);
    });

    usersApi.getUser({ id: "bc40674a-a85b-516a-b900-941cc36bfb67" }).then((user) => {
      setUser2(user);
    });

    fetch("https://devapi.mesensei.com/apps/marsu", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApp(data);
      });

    isAuthenticated().then((auth) => {
      setIsAuthToken(auth);
    });

    const socket = io(BASE_URL /*, { transports: ['websocket', 'polling'] }*/);

    const onConnect = () => {
      setIsSocketConnected(true);
    };

    const onDisconnect = () => {
      setIsSocketConnected(false);
    };

    const onPingEvent = (event: string) => {
      setPingEvents((events) => [...events, event]);
    };

    const onMessageEvent = (event: string) => {
      setMessageEvents((events) => [...events, event]);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageEvent);
    socket.on("ping", onPingEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onMessageEvent);
      socket.off("ping", onPingEvent);
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <b>API:</b>
      <div>Status: {app ? "OK" : "Error"}</div>
      <br />
      <b>OpenAPI apiClient:</b>
      <div>Status: {page ? "OK" : "Error"}</div>
      <br />
      <b>App details:</b>
      <div>Identifier: {app ? app.identifier : "-"}</div>
      <div>Name: {app ? app.name : "-"}</div>
      <div>id: {app ? app.id : "-"}</div>
      <br />
      <b>Current user details:</b>
      <div>Is authenticated: {isAuthToken ? "YES" : "NO"}</div>
      <div>name: {user ? user.name : "-"}</div>
      <div>id: {user ? user.id : "-"}</div>
      <br />
      <b>User profile:</b>
      <div>name: {user2 ? user2.name : "-"}</div>
      <div>id: {user2 ? user2.id : "-"}</div>

      <div>
        {user2 &&
          user2.contentArea &&
          user2.contentArea.contentContainers &&
          user2.contentArea.contentContainers.map((container, index) => (
            <div key={index}>
              {container.name +
                ": " +
                container.items.map((item, index) => {
                  return item.name;
                })}
            </div>
          ))}
      </div>
      <br />
      <b>socket.io:</b>
      <div>Socket connected: {isSocketConnected ? "YES" : "NO"}</div>
      <div>Ping events received: {pingEvents.length}</div>
      <div>Message events received: {messageEvents.length}</div>
      <br />
      <div>Message events:</div>
      <ul>
        {messageEvents.map((event, index) => (
          <li key={index}>{event}</li>
        ))}
      </ul>
      <br />
    </div>
  );
};

export default TestContainer;
