/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContentArea } from './ContentArea';
import {
    ContentAreaFromJSON,
    ContentAreaFromJSONTyped,
    ContentAreaToJSON,
    ContentAreaToJSONTyped,
} from './ContentArea';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    description: string;
    /**
     * Content of the user.
     * @type {ContentArea}
     * @memberof User
     */
    contentArea: ContentArea;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('contentArea' in value) || value['contentArea'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'contentArea': ContentAreaFromJSON(json['contentArea']),
    };
}

  export function UserToJSON(json: any): User {
      return UserToJSONTyped(json, false);
  }

  export function UserToJSONTyped(value?: User | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'contentArea': ContentAreaToJSON(value['contentArea']),
    };
}

