/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContentArea } from './ContentArea';
import {
    ContentAreaFromJSON,
    ContentAreaFromJSONTyped,
    ContentAreaToJSON,
    ContentAreaToJSONTyped,
} from './ContentArea';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    imageUrl?: string;
    /**
     * Visible name of the user. May contain both first and last name.
     * @type {string}
     * @memberof UserResponse
     */
    name: string;
    /**
     * Visible description of the user. May be biography, title, or any selected field from user's profile type fields.
     * @type {string}
     * @memberof UserResponse
     */
    description: string;
    /**
     * Content of the user.
     * @type {ContentArea}
     * @memberof UserResponse
     */
    contentArea?: ContentArea;
}

/**
 * Check if a given object implements the UserResponse interface.
 */
export function instanceOfUserResponse(value: object): value is UserResponse {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'contentArea': json['contentArea'] == null ? undefined : ContentAreaFromJSON(json['contentArea']),
    };
}

  export function UserResponseToJSON(json: any): UserResponse {
      return UserResponseToJSONTyped(json, false);
  }

  export function UserResponseToJSONTyped(value?: UserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'contentArea': ContentAreaToJSON(value['contentArea']),
    };
}

