import BottomNavigationBar from "./BottomNavigationBar";
import ActionButtons from "./ActionButtons";
import { ProfileHeader } from "./ProfileHeader";

interface UserProfileScreenExtendedProps {
  userName: {
    firstName: string;
    shorterLastName: string;
  };
  isFavorited: boolean;
  onFavoriteChange: (newState: boolean) => void;
}

export default function UserProfileScreenExtended({
  userName,
  isFavorited,
  onFavoriteChange,
}: UserProfileScreenExtendedProps) {
  return (
    <>
      <div className="relative w-full bg-user-purple">
        <div className="flex flex-col justify-center gap-8">
          <ProfileHeader
            onBackClick={() => window.history.back()}
            isFavorited={isFavorited}
            onFavoriteChange={onFavoriteChange}
            centerContent={
              <span className="text-lg font-bold">
                {userName.firstName + " " + userName.shorterLastName}
              </span>
            }
          />
        </div>
        <div className="relative flex flex-col items-center pb-10">
          <div className="relative">
            <div className="h-[75px] w-[75px] overflow-hidden rounded-full">
              <img
                src={"/user-profile-picture.png"}
                alt="profile picture"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="absolute bottom-0 right-0 mb-1 h-3.5 w-3.5 rounded-full bg-[#7ED321] ring-1 ring-white"></div>
          </div>
        </div>
      </div>
      <div className="relative z-10 -mt-7 flex flex-col">
        <ActionButtons />
      </div>
      <BottomNavigationBar />
    </>
  );
}
