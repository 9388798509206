import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AuthProvider } from "./routes/(Auth)/AuthProvider";
import AuthWrapper from "./routes/(Auth)/AuthWrapper";
import Root from "./routes/Root";
import Chat from "./routes/Chat/Chat";
import Content from "./routes/Content";
import EnterEmail from "./routes/(Auth)/EnterEmail/EnterEmail";
import AuthenticatedRoute from "./routes/(Auth)/AuthenticatedRoute";
import LoginCode from "./routes/(Auth)/LoginCode/LoginCode";
import Test from "./routes/Test";
import UserProfileScreen from "./routes/UserProfileScreen/UserProfileScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <AuthWrapper>
          <AuthenticatedRoute />
        </AuthWrapper>
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        element: <Root />,
        children: [
          { index: true, element: <Content /> },
          { path: "chat/:chatId", element: <Chat /> },
          { path: "content", element: <Content /> },
          { path: "test", element: <Test /> },
          { path: "user-profile", element: <UserProfileScreen /> },
        ],
      },
    ],
  },
  {
    path: "enter-email",
    element: (
      <AuthProvider>
        <EnterEmail />
      </AuthProvider>
    ),
  },
  {
    path: "login-code",
    element: (
      <AuthProvider>
        <LoginCode />
      </AuthProvider>
    ),
  },
  {
    path: "/test",
    element: <Test />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
