import { FaHeart, FaCommentDots, FaSearch, FaCalendarDay, FaCircle } from "react-icons/fa";

export default function BottomNavigationBar() {
  return (
    <div className="absolute bottom-0 left-0 right-0 bg-white shadow-2xl shadow-gray-400">
      <div className="flex items-center justify-around py-5">
        <button className="flex flex-col items-center gap-1">
          <FaCircle className="text-icon-gray opacity-50 hover:opacity-100" size={24} />
        </button>
        <button className="flex flex-col items-center gap-1">
          <FaSearch className="text-icon-gray opacity-50 hover:opacity-100" size={24} />
        </button>
        <button className="flex flex-col items-center gap-1">
          <FaCalendarDay className="text-icon-gray opacity-50 hover:opacity-100" size={24} />
        </button>
        <button className="flex flex-col items-center gap-1">
          <div className="relative">
            <FaCommentDots className="text-icon-gray opacity-50 hover:opacity-100" size={24} />
            <div className="absolute bottom-0 right-0 h-2 w-2 rounded-full bg-red-500"></div>
          </div>
        </button>
        <button className="flex flex-col items-center gap-1">
          <FaHeart className="text-icon-gray opacity-50 hover:opacity-100" size={24} />
        </button>
      </div>
    </div>
  );
}
