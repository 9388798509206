/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    imageIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    appId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDto
     */
    profileFields: Array<string>;
}

/**
 * Check if a given object implements the CreateUserDto interface.
 */
export function instanceOfCreateUserDto(value: object): value is CreateUserDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('imageIdentifier' in value) || value['imageIdentifier'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('appId' in value) || value['appId'] === undefined) return false;
    if (!('profileFields' in value) || value['profileFields'] === undefined) return false;
    return true;
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
    return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'imageIdentifier': json['imageIdentifier'],
        'role': json['role'],
        'appId': json['appId'],
        'profileFields': json['profileFields'],
    };
}

  export function CreateUserDtoToJSON(json: any): CreateUserDto {
      return CreateUserDtoToJSONTyped(json, false);
  }

  export function CreateUserDtoToJSONTyped(value?: CreateUserDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'email': value['email'],
        'phone': value['phone'],
        'imageIdentifier': value['imageIdentifier'],
        'role': value['role'],
        'appId': value['appId'],
        'profileFields': value['profileFields'],
    };
}

