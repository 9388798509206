import { FaPhone, FaCommentDots, FaVideo } from "react-icons/fa";
import { IconType } from "react-icons";

interface ActionButtonProps {
  icon: IconType;
  isEnabled: boolean;
  isAvailable: boolean;
  label?: string;
}

interface Feature {
  enabled: boolean;
  available: boolean;
}

interface Features {
  call?: Feature;
  chat?: Feature;
  video?: Feature;
}

interface ActionButtonsProps {
  features?: Features;
  showLabels?: boolean;
}

const ActionButton = ({ icon: Icon, isEnabled, isAvailable, label }: ActionButtonProps) => {
  if (!isEnabled) return null;

  return (
    <button className="flex flex-col items-center" disabled={!isAvailable} aria-label={label}>
      <div
        className={`mb-2 flex h-[60px] w-[60px] items-center justify-center rounded-full ${isAvailable ? "bg-icon-purple hover:scale-105" : "cursor-not-allowed bg-gray-300"}`}
      >
        <Icon size={24} />
      </div>
    </button>
  );
};

const ActionButtons = ({
  features = {
    call: { enabled: true, available: false },
    chat: { enabled: true, available: true },
    video: { enabled: false, available: true },
  },
}: ActionButtonsProps) => {
  const buttons = [
    { icon: FaPhone, feature: "call" as const, label: "Call" },
    { icon: FaCommentDots, feature: "chat" as const, label: "Chat" },
    { icon: FaVideo, feature: "video" as const, label: "Video" },
  ];

  return (
    <div className="flex justify-center gap-8">
      {buttons.map(({ icon, feature, label }) => (
        <ActionButton
          key={feature}
          icon={icon}
          isEnabled={features[feature]?.enabled ?? false}
          isAvailable={features[feature]?.available ?? false}
          label={label}
        />
      ))}
    </div>
  );
};

export default ActionButtons;
