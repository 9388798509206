import { FaChevronLeft, FaHeart, FaRegHeart, FaEllipsisV } from "react-icons/fa";
import { IconType } from "react-icons";

interface HeaderButtonProps {
  icon: IconType;
  onClick?: () => void;
  className?: string;
  ariaLabel?: string;
}

const HeaderButton = ({ icon: Icon, onClick, className = "", ariaLabel }: HeaderButtonProps) => (
  <button
    onClick={onClick}
    aria-label={ariaLabel}
    className={`transition-transform hover:scale-110 active:scale-95 ${className} `}
  >
    <Icon size={24} />
  </button>
);

interface ProfileHeaderProps {
  onBackClick: () => void;
  showFavorite?: boolean;
  showMenu?: boolean;
  isFavorited: boolean;
  onFavoriteChange?: (isFavorited: boolean) => void;
  onMenuOpen?: () => void;
  centerContent?: React.ReactNode;
}

export const ProfileHeader = ({
  onBackClick,
  showFavorite = true,
  showMenu = true,
  isFavorited,
  onFavoriteChange,
  onMenuOpen,
  centerContent,
}: ProfileHeaderProps) => {
  const handleFavoriteClick = () => {
    onFavoriteChange?.(!isFavorited);
  };

  return (
    <div className="relative z-10 flex items-center justify-between p-4">
      <HeaderButton icon={FaChevronLeft} onClick={onBackClick} ariaLabel="Go back" />

      {centerContent && <div className="absolute left-1/2 -translate-x-1/2">{centerContent}</div>}

      <div className="flex items-center gap-4">
        {showFavorite && (
          <HeaderButton
            icon={isFavorited ? FaHeart : FaRegHeart}
            onClick={handleFavoriteClick}
            ariaLabel={isFavorited ? "Remove from favorites" : "Add to favorites"}
          />
        )}
        {showMenu && <HeaderButton icon={FaEllipsisV} onClick={onMenuOpen} ariaLabel="Open menu" />}
      </div>
    </div>
  );
};
